import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import { convertToUrl } from "../utils/stringConverter";
import toast from "react-hot-toast";

export default function UserCasino() {
  const [cassinoData, setCassinoData] = useState([]);
  const [userData, setUserData] = useState([]);

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ marginTop: "65px" }}
      >
        <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <PageHeader
            title="LEDGER"
            setCassinoData={setCassinoData}
            setUserData={setUserData}
          />
        </div>
        <div className="menu" id="menu" align="center">
          <ul className="nav">
            <li className="active abc w-100">
              <Link to="/MainMenu" style={{ height: "32.1px" }}>
                BACK TO MAIN MENU
              </Link>
            </li>
          </ul>
        </div>

        <div className="container pt-5">
          <div className="row">
            {cassinoData.length > 0 ? (
              cassinoData.map((match, index) => (
                <div className="col-md-6" key={index}>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        match.isLocked === 1 ||
                        userData.cassino_status !== true
                      ) {
                        toast.error("This match is locked");
                      } else {
                        window.location.href = `/casino-bet-page-${convertToUrl(
                          match.name
                        )}`;
                      }
                    }}
                  >
                    <div className=" d-flex flex-column gap-3 align-items-center">
                      <img src={match.image} alt={match.name} height="112" />
                      <p
                        style={{
                          color: "#000000",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        {match.name}
                      </p>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="col-md-12 text-center pb-5">
                <h3>Loading data...</h3>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
